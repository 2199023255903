<template>
    <div class="home-page">
        <Header/>
        <div class="main-content">
            <img class="b-tc" src="../assets/tcbanner.png">
            <div class="title">赛项介绍</div>
            <div class="p-content">
                <div class="p-left">
                    <div v-for="(item, index) in titleList" :class="selectIndex===index?'item select':'item'" :key="index"  @click="selectIndex=index">
                        {{item}}
                        <div v-if="selectIndex!==index" class="triangle-down"/>
                        <div :style="{borderTop:selectIndex===index?'16px solid #2F50AB':'16px solid #CCCCCC'}" style="top:74px" class="triangle-down"/>
                    </div>
                </div>
                <div style="width: calc(100% - 32px - 210px);margin-left:32px">
                    <div class="p-title">{{titleList[selectIndex]}}</div>
                    <div class="line"/>
                    <div class="p-desc" v-html="obj[selectIndex].content"></div>
                </div>
                
            </div>
            
        </div>
        
        <Footer/>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed:
        mapState({
            obj: function (state){
                console.log(state.globalConfig,'state.globalConfig');
                return {
                    0:state.globalConfig.event_content,
                    1:state.globalConfig.entry_matters,
                    2:state.globalConfig.competition_process,
                    3:state.globalConfig.score_standard,
                    4:state.globalConfig.award_setting,
                };
            }
        })
    ,
    data() {
        return {
            selectIndex: 0,
            titleList:[
                '赛项内容',
                '参赛事宜',
                '竞赛流程',
                '评分标准',
                '奖项设置',
            ]
        }
    },
    mounted() {
        if(this.$route.query.type){
            this.selectIndex = Number(this.$route.query.type);
        }
    },
    methods: {
        
    },
}
</script>
<style lang="scss" scoped>
.main-page{
    width: 1200px;
    margin: auto;
}
.main-content{
    width:1200px;
    margin: auto;
    min-height: 500px;
    position: relative;
    .title{
        height:89px;
        line-height: 89px;
        font-size: 28px;
        padding-left: 64px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
    }
    .b-tc{
        width:1920px;
        z-index:-1;
        position: absolute;
        left: calc(50% - 960px);
    }
    .p-content{
        width:100%;
        box-sizing: border-box;
        background: #fff;
        display: flex;
        box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.17);
        padding: 0 17px 15px 17px;
        .p-left{
            width: 210px;
        }
        .p-title{
            width:100%;
            height: 51px;
            line-height: 51px;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #343434;
            text-align: center;
        }
        .line{
            width:100%;
            height: 1px;
            background: #BFBFBF;
        }
        .p-desc{
            padding: 17px 0;
        }
        .p-left{
            margin-top: 20px;
            .item{
                width:100%;
                height: 75px;
                line-height: 75px;
                text-align: center;
                background: #CCCCCC;
                border-radius: 10px;
                margin-bottom: 25px;
                color: #676767;
                font-size: 20px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                position: relative;
                cursor: pointer;
            }
            .select{
                background: #2F50AB !important;
                color: #fff !important;
            }
        }
    }
}
.triangle-down {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 16px solid #fff;
    position: absolute;
    top:0px;
    left: 97px;
}
</style>
